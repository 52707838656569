<template>
  <div class="page">
    
    <GoogleMapMain
      v-if="page && page.template == 'template_map.php'"
    />
    <AboutCompany
      v-else-if="page && page.template == 'template_about.php'"
    />
    <SalesPropos
      v-else-if="page && page.template == 'template_promo.php'"
    />
    <WorkIn
      v-else-if="page && page.template == 'template_vacancy.php'"
    />
    <PromoParper
      v-else-if="page && page.template == 'template_paper.php'"
    />

    <CooperationPageVue
      v-else-if="page && page.template == 'template_cooperation.php'"
    />

    <FeedbackPageVue
      v-else-if="page && page.template == 'template_feedback.php'"
    />

    <div v-else>
      <div v-if="page && page.content" v-html="page.content.rendered"></div>
      
    </div>

  </div>
</template>
<script>

import { mapGetters, mapActions } from "vuex";

import GoogleMapMain from "../components/pages/GoogleMapMain.vue";
import AboutCompany from "../components/pages/AboutCompany.vue";
import SalesPropos from "../components/pages/SalesPropos.vue";
import WorkIn from "../components/pages/WorkIn.vue";
import PromoParper from "../components/pages/promoParper.vue";
import CooperationPageVue from '../components/pages/CooperationPage.vue';
import FeedbackPageVue from '../components/pages/FeedbackPage.vue';
import { gsapMixin } from '../mixins/gsapMixin';

export default {
  mixins: [gsapMixin],
  components: {
    GoogleMapMain,
    AboutCompany,
    SalesPropos,
    WorkIn,
    PromoParper,
    CooperationPageVue,
    FeedbackPageVue,
  },
  data() {
    return {
      page: null,
      pageId: null,
    };
  },
  watch: {
    $route(to) {
      const pageSlug = to.params?.pageSlug;
        this.page = null
        this.loadPageContent(pageSlug);
    },
  },
  created() {
    const pageSlug = this.$route.params?.pageSlug;
    this.loadPageContent(pageSlug);

    // watch(
    //   () => this.$route, // Access the route using this.$route
    //   (to, from) => {
    //     console.log(to)
    //     const pageSlug = to.params?.pageSlug;
    //     this.page = null

    //     this.loadPageContent(pageSlug);
    //   }
    // );
  },
  computed: {
    ...mapGetters(["getPage"]),
  },
  beforeUnmount() {
    const stylesheetId = `elementor-post-${this.pageId}-css`;
    const stylesheetLink = document.getElementById(stylesheetId);

    if (stylesheetLink) {
      document.head.removeChild(stylesheetLink);
    }
  },
  methods: {
    ...mapActions(["fetchPage"]),

    loadPageContent(slug) {
      this.fetchPage(slug)
        .then(() => {

          if(!this.getPage || !this.getPage.length) {
            this.$router.push({name: '404'})
            return
          }

          this.page = this.getPage[0];
          this.pageId = this.page.id;
          this.loadCssFile('custom-frontend.min')
          this.loadCssFile('global')
          this.loadCssFile('post-2992');
          this.loadCssFile();

        })
        .catch((error) => {
          console.error(error);
        });
    },

    loadCssFile(cssName) {
      const stylesheetId = cssName ? `elementor-${cssName}` : `elementor-post-${this.pageId}-css`;
  
      if (document.getElementById(stylesheetId)) return;

      const domainElement =
        document.head && document.head.querySelector('[property="og:url"]');
      const domain = domainElement
        ? domainElement.content
        : "https://blyz.wyr.com.ua";

      const url = cssName ? `${domain}/wp-content/uploads/elementor/css/${cssName}.css` : `${domain}/wp-content/uploads/elementor/css/post-${this.pageId}.css`;
     

      return new Promise((resolve, reject) => {
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.href = url;
        link.id = stylesheetId;
        link.onload = () => resolve();
        link.onerror = () =>
          reject(new Error(`Failed to load CSS file: ${url}`));
        document.head.appendChild(link);
      }).catch((reject) => {
        if (reject) {
          const stylesheetId = cssName ? `elementor-${cssName}` : `elementor-post-${this.pageId}-css`;
          const stylesheetLink = document.getElementById(stylesheetId);

          if (stylesheetLink) {
            document.head.removeChild(stylesheetLink);
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scope>
//@import "../assets/scss/elementor.scss";
</style>
