<template lang="">
  <article v-if="page">
    <div
      v-if="page.content && page.content.rendered"
      v-html="page.content.rendered"
    ></div>

    <section class="vacances-section" id="vacances-list">
      <div class="container">
        <h2>Підібрати вакансію</h2>
        <div class="vacances">
          <div class="vacances-filtres">
            <div class="col">
              <Multiselect
                placeholder="Місто"
                v-model="citySelected"
                :options="cityList"
                @select="filterVacancies"
                @clear="filterVacancies"
              />
            </div>
            <div class="col">
              <Multiselect
                placeholder="Тип вакансії"
                v-model="workTypeSelected"
                :options="workType"
                :close-on-select="true"
                @select="filterVacancies"
                @clear="filterVacancies"
              />
            </div>
            <div class="col">
              <Multiselect
                placeholder="Зайнятість"
                v-model="WorkDateSelected"
                :options="vacancesWorkDate"
                :close-on-select="true"
                @select="filterVacancies"
                @clear="filterVacancies"
              ></Multiselect>
            </div>
          </div>
          <div class="vacances-list-wrap">
            <ul class="vacances-list">
              <li class="vacancy-item" v-for="item in vacancesList" :key="item">
                <a :href="item.acf_fields.work_link.url ? item.acf_fields.work_link.url : '#'" v-if="item.acf_fields.work_link" class="animate-box-hover" target="_blank">
                  <div class="row">
                    <div class="col content">
                      <p class="title">{{ item.title }}</p>
                      <address class="city" v-if="item.acf_fields.city">{{ item.acf_fields.city.name }}</address>
                    </div>
                    <div class="col icon-block">
                      <span class="icon"><svg version="1.1" width="72" height="56" style="
"><path d="M0 0 C5.43733055 4.52991796 10.46458931 9.34294216 15.4375 14.375 C16.53481445 15.47908203 16.53481445 15.47908203 17.65429688 16.60546875 C19.43852723 18.40136074 21.22036124 20.19955985 23 22 C18.47008204 27.43733055 13.65705784 32.46458931 8.625 37.4375 C7.52091797 38.53481445 7.52091797 38.53481445 6.39453125 39.65429688 C4.59863926 41.43852723 2.80044015 43.22036124 1 45 C-0.65 43.35 -2.3 41.7 -4 40 C0.29 35.71 4.58 31.42 9 27 C-12.78 26.505 -12.78 26.505 -35 26 C-35 23.69 -35 21.38 -35 19 C-20.48 18.67 -5.96 18.34 9 18 C4.71 13.71 0.42 9.42 -4 5 C-2.68 3.35 -1.36 1.7 0 0 Z " fill="#F7FBF6" transform="translate(43,5)"></path></svg>
</span>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="counter-section">
      <div class="container">
        <div class="counter-block" v-if="pageFields && pageFields.counters">

          <SingleCounter
            v-for="count in pageFields.counters"
            :key="count"
            :count="count.count"
            :title="count.title"
          />
        </div>
      </div>
    </section>
    <section class="form-sectio" v-if="false">
      <div class="container">
        <div class="row">
          <div class="col">
            <h3 class="title" v-if="pageFields && pageFields.title_vacancy_call">Просто надішліть своє резюме</h3>
            <p class="text" v-if="pageFields && pageFields.text_vacancy_call">Так ми зможемо підібрати для вас найкраще робоче місце</p>
          </div>
          <div class="col">
            <button class="btn" v-if="pageFields && pageFields.text_vacancy_call">НАДІСЛАТИ РЕЗЮМЕ</button>
          </div>
        </div>
      </div>
    </section>
    <section class="section-team" v-if="pageFields && pageFields.image_team">
      <div class="container">
        <picture class="img" v-if="pageFields && pageFields.image_team && pageFields.image_team.sizes">
          <img :src="pageFields.image_team.sizes['2048x2048']" :alt="pageFields.image_team.name">
        </picture>
      </div>
    </section>
  </article>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Multiselect from "@vueform/multiselect";
import SingleCounter from '../global/SingleCounter.vue';
import { gsapMixin } from '../../mixins/gsapMixin';

export default {
  mixins: [gsapMixin],
  components: {
    Multiselect,
    SingleCounter
  },

  data() {
    return {
      page: null,
      pageId: null,
      pageFields: null,
      vacancesList: null,
      vacancesWorkDate: ['Повна зайнятість', 'Для студентів'],
      WorkDateSelected: null,
      workType: ["В офісі", "В магазині"],
      workTypeSelected: null,
      cityList: ["Львів", "Івано-Франківськ", "Тернопіль"],
      citySelected: null,
    };
  },
  computed: {
    ...mapGetters([
      "getPage",
      "getSettings",
      "getVacances",
      "getVacancesWorkDate",
      "getVacancesCityList",
      "getVacancesWorkType"
    ]),
  },
  beforeUnmount() {
    const stylesheetId = `elementor-post-${this.pageId}-css`;
    const stylesheetLink = document.getElementById(stylesheetId);

    if (stylesheetLink) {
      document.head.removeChild(stylesheetLink);
    }
  },
  mounted() {
    const pageSlug = this.$route.params?.pageSlug;
    this.loadPageContent(pageSlug);
    this.loadVacancesList();
  },
  methods: {
    ...mapActions(["fetchVacances", "fetchPage"]),

    loadPageContent(slug) {
      if(this.getPage[0]) {
        this.page = this.getPage[0];
        this.pageId = this.page.id;
        this.pageFields = this.page.acf_fields;
        this.loadCssFile();
        return
      }
      this.fetchPage(slug).then(() => {
        this.page = this.getPage[0];
        this.pageId = this.page.id;
        this.pageFields = this.page.acf_fields;
        this.loadCssFile();
      });
    },

    loadVacancesList() {
      this.fetchVacances().then(() => {
        this.vacancesList = this.getVacances;
        this.cityList = this.getVacancesCityList
        this.vacancesWorkDate = this.getVacancesWorkDate.map((item) => item.name)
        this.workType = this.getVacancesWorkType.map((item) => item.name)

        if(this.$route.query.type) {
          const typeTaxonomy = this.getVacancesWorkType.filter((item)=> item.slug == this.$route.query.type)

          this.workTypeSelected = typeTaxonomy && typeTaxonomy.length ? typeTaxonomy[0].name : null

          const vacancySection = document.querySelector('#vacances-list');

          const elTop = vacancySection?.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({top: elTop - 50, behavior: "smooth"});
        }
      });
    },
    filterVacancies() {

      if (!this.workTypeSelected && !this.WorkDateSelected && !this.citySelected) {
        this.vacancesList = this.getVacances;
        return;
      }

      this.vacancesList = this.getVacances.filter((item) => {
        return (
          (this.isWorkDate(item.vacancy_type, this.workTypeSelected) || !this.workTypeSelected) &&
          (this.isWorkDate(item.work_date, this.WorkDateSelected) || !this.WorkDateSelected) &&
          (item.acf_fields.city && item.acf_fields.city.name === this.citySelected || !this.citySelected)
        );
      });

    },

    isWorkDate(obj, item) {
      if (!item) return false;  // Return false if item is undefined 
      return obj?.find((elem) => elem.term_name == item);
    },

    loadCssFile() {
      const stylesheetId = `elementor-post-${this.pageId}-css`;

      if (document.getElementById(stylesheetId)) return;

      const domainElement =
        document.head && document.head.querySelector('[property="og:url"]');
      const domain = domainElement
        ? domainElement.content
        : "https://blyz.wyr.com.ua";

      const url = `${domain}/wp-content/uploads/elementor/css/post-${this.pageId}.css`;

      return new Promise((resolve, reject) => {
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.href = url;
        link.id = stylesheetId;
        link.onload = () => resolve();
        link.onerror = () =>
          reject(new Error(`Failed to load CSS file: ${url}`));
        document.head.appendChild(link);
      }).catch((reject) => {
        if (reject) {
          const stylesheetId = `elementor-post-${this.pageId}-css`;
          const stylesheetLink = document.getElementById(stylesheetId);

          if (stylesheetLink) {
            document.head.removeChild(stylesheetLink);
          }
        }
      });
    },
  },
};
</script>
<style lang="scss">
  @import "../../assets/scss/work-in-page.scss";
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
