<template>
  <div class="form-wrap">
    <form
      class="mainForm"
      name="landingForm"
      onSubmit="createObject(); return false"
    >
      <input hidden id="shop_id" :value="selectedShop" />
      <input hidden id="type" :value="type" />
      <input hidden id="reason" :value="reasonSelected" />
      <p>
        <Multiselect
          v-if="!type"
          id="reasonSelect"
          placeholder="Причина звернення"
          v-model="reasonSelected"
          :options="reason"
         :close-on-select="true"
        />
        <input
          v-if="type"
          type="text"
          id="type-name"
          style="display: none"
          value="Пропозиція співпраці"
          disabled
        />
      </p>
      <p><input type="text" id="name" placeholder="І'мя" /></p>
      <p><input type="tel" id="tel-number" placeholder="Телефон" /></p>
      <p>
        <input type="email" id="user-email" placeholder="Електронна пошта" />
      </p>
      <p class="shop-address-wrap">
        <Multiselect
          id="shop-addres-select"
          placeholder="Магазин"
          v-model="selectedShop"
          :options="options"
           label="name"
           :searchable="true"
           :close-on-select="true"
        />
      </p>
      <p>
        <textarea
          type="text"
          id="message"
          rows="4"
          placeholder="Подробиці"
        ></textarea>
      </p>
      <p><input type="submit" value="Надіслати" /></p>
    </form>
  </div>
</template>
<script>
import Multiselect from "@vueform/multiselect";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    type: String,

  },
  components: {
    Multiselect,
  },
  data() {
    return {
      options: [],
      selectedShop: null,
      reason: ['Подяка', 'Скарга', 'Відгук про каву', 'Інше'],
      reasonSelected: null,

    };
  },
  computed: {
    ...mapGetters(["getShops"]),
  },
  mounted() {
    this.loadShops();
    this.loadJsFile('script')
  },
  reasonSelectSettings(){
    return {

    }
  },
  methods: {
     ...mapActions(["fetchShops"]),
    async loadShops() {
        await this.fetchShops()
        .then(() => {

          this.getShops.every((shop) => {
            const shopAddress = shop.acf_fields.shop_map.street_name ? 
                    `${shop.acf_fields.shop_map.city}, ${shop.acf_fields.shop_map.street_name} ${shop.acf_fields.shop_map.street_number}`
                    : shop.acf_fields.shop_adress;

            let id = `${shopAddress} id:${shop.title}`;
            let address = {
              value: id,
              name: shopAddress,
            };

            return this.options.push(address);
          });

        });
    },
    loadJsFile(fileName) {
            if (!fileName || document.getElementById(fileName)) return;

            const domainElement = document.head && document.head.querySelector('[property="og:url"]');
            const domain = domainElement ? domainElement.content : "https://blyz.wyr.com.ua";

            const url = `${domain}/wp-content/themes/blyzenko-vue/${fileName}.js`;
            console.log(url)

            return new Promise((resolve, reject) => {
                const checkJQueryLoaded = setInterval(() => {
                    const jQueryScript = document.getElementById('jquery-core-js');
                    if (typeof jQuery !== "undefined" && jQueryScript) {
                        clearInterval(checkJQueryLoaded);

                        const link = document.createElement("script");
                        link.type = "text/javascript";
                        link.src = url;
                        link.id = fileName;
                        link.onload = () => resolve();
                        link.onerror = () => reject(new Error(`Failed to load js file: ${url}`));

                        // Insert the script after the jQuery script
                        jQueryScript.parentNode.insertBefore(link, jQueryScript.nextSibling);
                    }
                }, 100); // Check every 100ms

                // Optional timeout to reject the promise if jQuery never loads (e.g., after 10 seconds)
                setTimeout(() => {
                    clearInterval(checkJQueryLoaded);
                    reject(new Error('jQuery did not load within the expected time.'));
                }, 10000); // 10 seconds
            }).catch((error) => {
                const fileLink = document.getElementById(fileName);
                if (fileLink) {
                    document.head.removeChild(fileLink);
                }
            });
        }
  },
};
</script>
<style lang="css">
.form-wrap {
  width: 100%;
  max-width: 620px;
  margin: 0 auto;

}

</style>
<style src="@vueform/multiselect/themes/default.css"></style>
