<template lang="">
  <div class="container" v-if="page" >
    <div v-if="page && page.content" v-html="page.content.rendered"></div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
    data() {
        return {
            page: null,
            pageId: null
        }
    },
    mounted() {
        const pageSlug = this.$route.params?.pageSlug;
        this.loadPageContent(pageSlug)
    },
      computed: {
    ...mapGetters(["getPage"]),
  },
    beforeUnmount() {

    const FilesIdList = ['flipbook.min', 'flipbook.book3.min', 'flipbook.swipe.min', 'iscroll.min', 'pdf.min', 'flipbook.pdfservice.min', 'embed', 'flip-flipbook.style', 'flip-font-awesome'];

    FilesIdList.forEach(item => {
        const elem = document.getElementById(`${item}`);

        if (elem && elem.parentNode === document.head) {
            document.head.removeChild(elem);
        }
    })

  },
    methods: {
        ...mapActions(["fetchPage"]),
    loadPageContent(slug) {
        const jsFilesIdList = ['flipbook.min', 'flipbook.book3.min', 'flipbook.swipe.min', 'iscroll.min', 'pdf.min', 'flipbook.pdfservice.min', 'embed'];
        if(this.getPage && this.getPage[0]) {
           
        this.loadJsFile(jsFilesIdList[0])
        this.loadJsFile(jsFilesIdList[1])
        this.loadJsFile(jsFilesIdList[2])
        this.loadJsFile(jsFilesIdList[3])
        this.loadJsFile(jsFilesIdList[4])
        this.loadJsFile(jsFilesIdList[5])
        this.loadJsFile(jsFilesIdList[6])
        this.loadCssFile('flipbook.style')
        this.loadCssFile('font-awesome')

        this.page = this.getPage[0];
        this.pageId = this.page.id;

            return
        }
        this.fetchPage(slug)
            .then(() => {
            if(!this.getPage || !this.getPage.length) {
                this.$router.push({name: '404'})
                return
            }

        this.loadJsFile(jsFilesIdList[0])
        this.loadJsFile(jsFilesIdList[1])
        this.loadJsFile(jsFilesIdList[2])
        this.loadJsFile(jsFilesIdList[3])
        this.loadJsFile(jsFilesIdList[4])
        this.loadJsFile(jsFilesIdList[5])
        this.loadJsFile(jsFilesIdList[6])
        this.loadCssFile('flipbook.style')
        this.loadCssFile('font-awesome')

        this.page = this.getPage[0];
        this.pageId = this.page.id;

            })
            .catch((error) => {
            console.error(error);
            });
        },
        loadJsFile(fileName) {
            if (!fileName || document.getElementById(fileName)) return;

            const domainElement = document.head && document.head.querySelector('[property="og:url"]');
            const domain = domainElement ? domainElement.content : "https://blyz.wyr.com.ua";

            const url = `${domain}/wp-content/plugins/real3d-flipbook/js/${fileName}.js`;

            return new Promise((resolve, reject) => {
                const checkJQueryLoaded = setInterval(() => {
                    const jQueryScript = document.getElementById('jquery-core-js');
                    if (typeof jQuery !== "undefined" && jQueryScript) {
                        clearInterval(checkJQueryLoaded);

                        const link = document.createElement("script");
                        link.type = "text/javascript";
                        link.src = url;
                        link.id = fileName;
                        link.onload = () => resolve();
                        link.onerror = () => reject(new Error(`Failed to load js file: ${url}`));

                        // Insert the script after the jQuery script
                        jQueryScript.parentNode.insertBefore(link, jQueryScript.nextSibling);
                    }
                }, 100); // Check every 100ms

                // Optional timeout to reject the promise if jQuery never loads (e.g., after 10 seconds)
                setTimeout(() => {
                    clearInterval(checkJQueryLoaded);
                    reject(new Error('jQuery did not load within the expected time.'));
                }, 10000); // 10 seconds
            }).catch((error) => {
                const fileLink = document.getElementById(fileName);
                if (fileLink) {
                    document.head.removeChild(fileLink);
                }
            });
        },
        loadCssFile(fileName) {

            const stylesheetId = `flip-${fileName}`;

            if (document.getElementById(stylesheetId)) return;

            const domainElement =
                document.head && document.head.querySelector('[property="og:url"]');
            const domain = domainElement
                ? domainElement.content
                : "https://blyz.wyr.com.ua";

            const url = `${domain}/wp-content/plugins/real3d-flipbook/css/${fileName}.css`;

            return new Promise((resolve, reject) => {
                const link = document.createElement("link");
                link.rel = "stylesheet";
                link.href = url;
                link.id = stylesheetId;
                link.onload = () => resolve();
                link.onerror = () =>
                reject(new Error(`Failed to load CSS file: ${url}`));
                document.head.appendChild(link);
            }).catch((reject) => {
                if (reject) {
                const stylesheetId = fileName;
                const stylesheetLink = document.getElementById(stylesheetId);

                if (stylesheetLink) {
                    document.head.removeChild(stylesheetLink);
                }
                }
            });
            },
    },
}
</script>
<style lang=""></style>
