<template lang="">
  <GoogleMap
    :api-key="mapKey"
    mapTypeId="terrain"
    class="map"
    style="width: 100%; height: 610px"
    :center="center"
    :zoom="state.zoomMap"
    :options="mapOptions"
    @load="onMapLoad"
    ref="mapRef"
  >
    <CustomMarker :options="markerOptions" @click="toggleInfoWindow">
      <svg width="34.267" height="53.561" viewBox="0 0 34.267 53.561">
          <g id="logo-icon" transform="translate(-8212 -2878.357)">
            <path id="Path_126" data-name="Path 126" d="M17.133,12.5C7.671,12.5,0,20.487,0,30.334c0,16.12,19.108,23.234,13.708,35.73,8.767-6.058,20.559-23.283,20.559-35.73C34.267,20.487,26.6,12.5,17.133,12.5Zm0,22.034a6.959,6.959,0,1,1,6.854-6.958A6.906,6.906,0,0,1,17.133,34.537Z" transform="translate(8212 2865.854)" fill="#42af2a"/>
          </g>
        </svg>

      <InfoWindow :options="{ position: markerOptions.position }" v-if="shopInfo" v-model="isInfoWindowOpen" class="infowindow">
        <div id="content">
          <div class="shop-image" v-if="shopInfo.shop_image">
            <img
              :src="shopInfo.shop_image.sizes.medium"
              class="img-fluid rounded-top"
              alt=""
            />
          </div>
          <div class="shop-info">
          <p class="addres">
            <strong
              >в м. {{ shopInfo.shop_map.city }}
              {{ shopInfo.shop_adress }}</strong
            >
          </p>
          <p class="shop-time">
            <time
              v-if="shopInfo && shopInfo.shop_start"
              :datetime="shopInfo.shop_start"
              >{{ shopInfo.shop_start }}</time
            >-
            <time
              v-if="shopInfo && shopInfo.shop_time_end"
              :datetime="shopInfo.shop_time_end"
              >{{ shopInfo.shop_time_end }}</time
            >
          </p>
          <ul class="services-list" v-if="services">
            <li class="services-list-count">Сервісів в магазині: <span>{{services.length}}</span></li>
            <li v-for="service in serviceList(services)" :key="service" class="services-list-item">
              {{ service.term_name }}
            </li>
            <li class="services-list-count"><a href="" class="btn-link" @click.prevent="scrollToAnchor('#services')">Переглянути всі</a></li>
          </ul>
          </div>
          <div class="buttons-wrap">
            <a :href='getShopButton()' class="btn dark-green single" target="__blank">Маршрут сюди</a>
          </div>
        </div>
      </InfoWindow>
    </CustomMarker>
  </GoogleMap>
</template>
<script>
import { reactive, ref, watch, computed, onUpdated } from "vue";
import { GoogleMap, CustomMarker, InfoWindow } from "vue3-google-map";

export default {
  props: {
    mapInfo: [Object, Array],
    services: [Object, Array],
  },

  components: { GoogleMap, CustomMarker, InfoWindow },

  setup(props) {
    const mapRef = ref(null);
    const shopInfo = JSON.parse(JSON.stringify(props)).mapInfo;
    const isInfoWindowOpen = ref(true);
    const mapKey = process.env.VUE_APP_MAP_KEY

    const state = reactive({
      zoomMap: 15,
    });

    const center = { lat: shopInfo.shop_map.lat, lng: shopInfo.shop_map.lng };
    const markerOptions = { position: center, anchorPoint: 'TOP_CENTER' };

    const mapStyle = [
      {
        elementType: "geometry",
        stylers: [
          {
            color: "#212121",
          },
        ],
      },
      {
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#212121",
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "administrative.country",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
      {
        featureType: "administrative.land_parcel",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#bdbdbd",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
          {
            color: "#181818",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#1b1b1b",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#2c2c2c",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#8a8a8a",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [
          {
            color: "#373737",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
          {
            color: "#3c3c3c",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#42af2a",
          },
        ],
      },
      {
        featureType: "road.highway.controlled_access",
        elementType: "geometry",
        stylers: [
          {
            color: "#4e4e4e",
          },
        ],
      },
      {
        featureType: "road.highway.controlled_access",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#42af2a",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#ededed",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          {
            color: "#000000",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#3d3d3d",
          },
        ],
      },
    ];

    function getShopButton() {
        return shopInfo && shopInfo.shop_map.lat && shopInfo.shop_map.lng
          ? `https://www.google.com/maps/dir/?api=1&destination=${shopInfo.shop_map.lat},${shopInfo.shop_map.lng}`
          : "";
    }

    const mapOptions = {
      styles: mapStyle,
      disableDefaultUI: true, // Example option to disable default UI
    };

    const onMapLoad = (map) => {
      map.setOptions({ styles: mapStyle });
    };

    watch([() => mapRef.value?.ready], ([ready]) => {
      if (!ready) return;
      
      mapRef.value.map.setOptions({ styles: mapStyle });
    });

    const toggleInfoWindow = () => {
      isInfoWindowOpen.value = !isInfoWindowOpen.value;
    };

    const serviceList = (services) => {
      return services.filter(service => service.show_on_map)
    }

    const scrollToAnchor = (anchor) => {
      let el = document.querySelector('#services');
      if (el) {
        const elTop = el.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({top: elTop - 50, behavior: "smooth"});
      }
    }

    return {
      markerOptions,
      center,
      state,
      shopInfo,
      isInfoWindowOpen,
      mapOptions,
      mapRef,
      mapKey,
      onMapLoad,
      toggleInfoWindow,
      getShopButton,
      serviceList,
      scrollToAnchor
    };
  },
};
</script>
