import apiClient from '@/axios';

export const global = {
    state: () => ({
        nav: {
            main: null,
            footer: null
        },
        settings: [],
        errors: []
    }),

    actions: {
        async fetchNavigation({ commit }:any, data:any) {
            try {
                const response = await apiClient.get(`/menu/${data.slug}`);

                commit('setNav', {nav: response, type: data.type })
                commit('setNavError', null)

            }  catch (error) {
                commit('setNavError', error)
            }        
              
          },
        async fetchGlobalSetting({ commit }:any, slug:string) {
            try {
                const response = await apiClient.get(`/theme-options`);

                commit('setGlobalSettings', response)
                commit('setNavError', null)

            }  catch (error) {
                commit('setNavError', error)
            }        
              
          },
    },
    mutations: {
        setNav(state:any, data:any) {

            if(!data) {
                state.nav[data.type] =  []
                return
            }

            state.nav[data.type] = data.nav.data
        },
        setGlobalSettings(state:any, settings:any) {
            if(!settings) {
                state.settings = []
                return
            }
            state.settings = settings.data
        },

        setNavError(state:any, error:any) {
            if(!error) {
                state.errors = []
            }

            state.errors = error
        }
    },
    getters: {
        getNav(state:any) {
            return state.nav
        },
        getSettings(state:any) {
            return state.settings
        }
    }
}
