<template lang="">
    <main class="site-content">
        <PreloaderVue :show="showPreloader"/>
        <slot></slot>
    </main>
</template>
<script>
import { mapGetters } from "vuex";

import PreloaderVue from './PreloaderVue.vue';
export default {
    components: {
        PreloaderVue
    },
    data() {
    return {
      showPreloader: {}
    }
  },
  watch: {
    $route(from) {
      this.anchorHashCheck();

      if(from && from.name != 'vancncy') {
          this.showPreloader = null
      }
    },

    getPage(val){
      if(val) {

        this.showPreloader = this.getPage
      }
    },

    getSingleShop(val){
        
      if(val) {
        this.showPreloader = this.getSingleShop
      }
    },
  },
  computed: {
        ...mapGetters(["getPage", "getSingleShop"]),
    },
    mounted() {
      this.anchorHashCheck();
      
  },
  methods: {
    anchorHashCheck() {
      let url = window.location.hash;      

      const intervalId = setInterval(function(){
        if(!window.location.hash || window.location.hash == "") return

        let el = document.getElementById(window.location.hash.slice(1));
        
        if (el) { 
          clearInterval(intervalId); 

          if (url) {
            let el = document.getElementById(window.location.hash.slice(1));
            
            if (el) {
              const elTop = el.getBoundingClientRect().top + window.scrollY;
              window.scrollTo({top: elTop - 50, behavior: "smooth"});
            }
          }
        }
      }, 300);
    }
  },
}
</script>
<style lang="">
    
</style>